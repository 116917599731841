import React from 'react';
import './Describe.css';

const Describe: React.FC = () => {
  return (
    <section id="describe" className="describe-section">
      <div className="describe-content">
        <h2>Turbocharge Your Tech</h2>
        <p>Lightning-fast solutions for Cloud and Big Data challenges. We help accelerate your digital transformation, to drive innovation and growth at unprecedented speeds.</p>
        <p>From rapid cloud migrations to high-velocity data architecture optimizations, we're here to supercharge your data-driven decision making.</p>
      </div>
      <div className="image-container" style={{ textAlign: 'center' }}>
        <img src={require('../assets/cloud-pipelines.jpg')} alt="Cloud Pipelines" className="cloud-pipelines" style={{ width: '60%', height: 'auto' }} />
      </div>
    </section>
  );
};

export default Describe;