import React, { useState } from 'react';
import axios from 'axios';
import './Contact.css';
import { API_URL } from '../config';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    country_code: '+27',
    phone_number: '',
    company_name: '',
    job_title: '',
    service_selected: '',
    project_description: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log('Form Data:', formData); // Log form data to the console

    try {
      const response = await axios.post(
        `${API_URL}/tech_api/business-form/`,
        formData,
        {
          auth: {
            username: process.env.REACT_APP_API_USERNAME || '',
            password: process.env.REACT_APP_API_PASSWORD || ''
          }
        }
      );

      if (response.status === 201) {
        alert('Form submitted successfully!');
        // Reset form
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          country_code: '+27',
          phone_number: '',
          company_name: '',
          job_title: '',
          service_selected: '',
          project_description: ''
        });
      } else {
        alert('An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact" className="contact-section">
      <h2>Contact Us</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-column">
            <input type="text" name="first_name" placeholder="First Name" required value={formData.first_name} onChange={handleChange} />
            <input type="text" name="last_name" placeholder="Last Name" required value={formData.last_name} onChange={handleChange} />
          </div>
          <div className="form-column">
            <input type="email" name="email" placeholder="Email" required value={formData.email} onChange={handleChange} />
            <div className="phone-input">
              <select name="country_code" value={formData.country_code} onChange={handleChange}>
                <option value="+27">+27</option>
                <option value="+1">+1</option>
                <option value="+44">+44</option>
                <option value="+91">+91</option>
                {/* Add more country codes as needed */}
              </select>
              <input type="tel" name="phone_number" placeholder="Phone Number" required value={formData.phone_number} onChange={handleChange} />
            </div>
          </div>
        </div>
        <input type="text" name="company_name" placeholder="Company Name" required value={formData.company_name} onChange={handleChange} />
        <input type="text" name="job_title" placeholder="Job Title" required value={formData.job_title} onChange={handleChange} />
        <select name="service_selected" required value={formData.service_selected} onChange={handleChange}>
          <option value="" disabled>Select Service</option>
          <option value="cloud_solutions">Cloud Solutions</option>
          <option value="machine_learning">Machine Learning & AI</option>
          <option value="data_architecture">Data Architecture & Analytics</option>
          <option value="devops">DevOps & Automation</option>
          <option value="software_development">Custom Software Development</option>
          <option value="other">Other</option>
        </select>
        <textarea 
          name="project_description"
          placeholder="Tell us about your project or requirements (optional)" 
          rows={4}
          value={formData.project_description}
          onChange={handleChange}
        ></textarea>
        <button 
          type="submit" 
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </section>
  );
};

export default Contact;