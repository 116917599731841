import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Describe from './components/Describe';
import Services from './components/Services';
import Contact from './components/Contact';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <Describe />
        <Services />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default App;