import React from 'react';
import './Services.css';

const Services: React.FC = () => {
  return (
    <section id="services" className="services-section">
      <h2>Our Services</h2>
      <div className="services-container">
        <table className="services-table">
          <tbody>
            <tr>
              <td className="service-item">
                <h3>Cloud Solutions</h3>
                <p>Scalable and secure cloud infrastructure tailored to your needs in Azure, AWS, and Google Cloud.</p>
              </td>
              <td className="service-item">
                <h3>Big Data Architecture</h3>
                <p>Building tailored data architectures to empower your business with actionable insights and informed decision-making.</p>
              </td>
            </tr>
            <tr>
              <td className="service-item">
                <h3>Data Engineering</h3>
                <p>Robust data pipelines and infrastructure to transform and manage your data effectively.</p>
              </td>
              <td className="service-item">
                <h3>Custom Software Development</h3>
                <p>Tailor-made software solutions to address your unique challenges.</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Services;